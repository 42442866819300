<template>
    <highcharts :options="demandChart" />
  </template>
  
  <script>
  import dayjs from 'dayjs';
  import Highcharts from 'highcharts';
  import { Chart } from 'highcharts-vue';
  import { i18n } from '../main';
  
  export default {
    components: {
      highcharts: Chart
    },
    props: {
      options: Array,
      relatorio: null,
      name: String
    },
    created() {
      const date = dayjs(this.relatorio.mesReferencia).format('MMMM/YYYY');
  
      const {
        datas,
        demP,
        demFP,
        demContP,
        demTolP,
        demContFP,
        demTolFP
      } = this.relatorio;
  
      const demandSerie = [
        demP,
        demFP,
        demContP,
        demTolP,
        demContFP,
        demTolFP
      ];
  
      this.demandChart.title.text = `${this.name} Demanda: ${date}`;
      this.demandChart.xAxis.categories = datas;
  
      this.demandChart.series.forEach((item, index) => {
        item.data = demandSerie[index];
      });
    },
    data: () => {
      return {
        demandChart: {
          title: {
            text: ''
          },
          xAxis: {
            categories: []
          },
          yAxis: [
            {
              allowDecimals: true,
              labels: {
                formatter: function () {
                  Highcharts.numberFormat(this.value, 0);
                }
              },
              floor: 0,
              title: {
                text: 'Valores em kW'
              }
            }
          ],
          plotOptions: {
            series: {
              marker: {
                enabled: false
              }
            }
          },
          series: [
            {
              type: 'column',
              name: i18n.tc('chartDemandaAtiva.tip'),
              color: '#FFCD00',
              data: [],
              tooltip: {
                valueDecimals: 1,
                valueSuffix: ' kW',
                valuePrefix: ''
              },
              legendIndex: 1
            },
            {
              type: 'column',
              name: i18n.tc('chartDemandaAtiva.off_the_tip'),
              color: '#004F59',
              data: [],
              tooltip: {
                valueDecimals: 1,
                valueSuffix: ' kW',
                valuePrefix: ''
              },
              legendIndex: 2
            },
            {
              type: 'line',
              name: i18n.tc('chartDemandaAtiva.contract_tip'),
              data: [],
              color: '#E57200',
              label: {
                enabled: false
              },
              tooltip: {
                valueDecimals: 1,
                valueSuffix: ' kW',
                valuePrefix: ''
              },
              lineWidth: 2.0,
              states: {
                hover: {
                  enabled: false
                }
              },
              legendIndex: 3
            },
            {
              type: 'line',
              name: i18n.tc('chartDemandaAtiva.tip_tolerance'),
              data: [],
              color: '#E57200',
              label: {
                enabled: false
              },
              tooltip: {
                valueDecimals: 1,
                valueSuffix: ' kW',
                valuePrefix: ''
              },
              lineWidth: 2.0,
              dashStyle: 'dash',
              states: {
                hover: {
                  enabled: false
                }
              },
              legendIndex: 4
            },
            {
              type: 'line',
              name: i18n.tc('chartDemandaAtiva.off_peak_contract'),
              data: [],
              color: '#009B3A',
              label: {
                enabled: false
              },
              tooltip: {
                valueDecimals: 1,
                valueSuffix: ' kW',
                valuePrefix: ''
              },
              lineWidth: 2.0,
              states: {
                hover: {
                  enabled: false
                }
              },
              legendIndex: 5
            },
  
            {
              type: 'line',
              name: i18n.tc('chartDemandaAtiva.off_peak_tolerance'),
              data: [],
              color: '#009B3A',
              label: {
                enabled: false
              },
              tooltip: {
                valueDecimals: 1,
                valueSuffix: ' kW',
                valuePrefix: ''
              },
              lineWidth: 2.0,
              dashStyle: 'dash',
              states: {
                hover: {
                  enabled: false
                }
              },
              legendIndex: 6
            }
          ],
          tooltip: {
            split: 'true'
          },
          credits: {
            enabled: false
          }
        }
      };
    }
  };
  </script>
  